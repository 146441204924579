<template>
  <v-app id="inspire">
    <app-bar />

    <app-drawer />

    <app-view />
  </v-app>
</template>

<script>
export default {
  name: "Index",

  components: {
    AppBar: () => import("@/components/core/AppBar"),
    AppDrawer: () => import("@/components/core/AppDrawer"),
    AppView: () => import("@/components/core/AppView"),
  },

  data: () => ({
    expandOnHover: false,
  }),
};
</script>